import './App.css';
import Base from './components/Base'
import Nav from './components/Nav'
import Container from './components/Container'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <Base>
        <Nav/>
        <Container/>
        <Footer/>
      </Base>
    </div>
  );
}

export default App;
