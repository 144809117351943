import React from 'react'
import Card from './Card'
const Section = ({children,meals,img}) => {
  return (
    <div className="container">
        {
            meals.map((item,idx)=>(
                <Card key={idx} img={process.env.PUBLIC_URL + item.img} food={item.food} types={item.types} />
            ))
        }

    </div>
  )
}

export default Section