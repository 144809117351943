import {useState}from 'react'
import logo from '../static/logo.png'

const Nav = () => {

  const [toggle,setToggle] = useState('none')
  const [arrow,setArrow] = useState('')


 const handleToggle = ()=>{
  if(toggle == 'none'){
    setToggle('block')
    setArrow('none')
  }else{
    setToggle('none')
    setArrow('')

  }
 }

  return (
    <div onClick={handleToggle} className="nav">
        <img className="logo" src={logo} alt="Logo" />

        <div id="toggle" style={{display:toggle}}>
        <a href="https://www.google.com/maps/dir/32.60416,-97.042432/tacos+y+pupusas+juanita/@32.6855003,-97.0481556,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x864e99a8b6e25cf1:0xc140a1b246ca2f48!2m2!1d-96.7535956!2d32.7874716"
        target="_blank">
        <p>4858 East Grand Ave #A</p>
        <p>Dallas , TX 75223</p>
        </a>
        <a href="tel:469-248-3215">
        <p>TEL 469-248-3215</p>
        </a>
        <p>TEXT 469-984-1442</p>
        <i className="material-icons" style={{fontSize:'36px'}}>arrow_drop_up</i>
        </div>
        <i className="material-icons" style={{display:arrow}}>arrow_drop_down</i>


    </div>
  )
}

export default Nav