import React from 'react'


const Base = ({children}) => {
  return (
    <div className="base">
        {children}
    </div>
  )
}

export default Base