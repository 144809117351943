import React from 'react'

const Card = ({img,food,types}) => {
  return (
    <div className="card">
        <img className="thumb" src={img} alt={`${food} image`} />
        <p className="menu-item-name">{food}</p>
          {

            types?.map((item,idx)=>(
              <p key={idx} className="menu-item-types">{item}</p>
            ))

        
            
          }
        

    </div>
  )
}

export default Card

