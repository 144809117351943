import React from 'react'

const Header = ({children,img}) => {
  return (
    <div className="section-header">
        <img src={img} alt="Menu Section Image" />
    </div>
  )
}

export default Header