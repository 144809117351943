import React from 'react'

const 
 Banner = () => {
    
  return (
    <div className="banner">
    
    </div>
  )
}

export default Banner
