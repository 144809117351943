import React from 'react'
import Section from './Section'
import img1 from '../static/aperitivos.png'
import img2 from '../static/platos.png'
import Header from './Header'
import Banner from './Banner'




const Container = ({children}) => {

    const appetizerArray = [
        {
            food:"Empanada's De Platano",
            img:"./empanadaDePlatano.JPEG"
        },
        {
            food:'Alitas',
            img:"./alitas.JPEG"
        },
        {
            food:'Nachos',
            img:"./nachos.JPEG"
        },
        
    ]

    const mainFoodArray = [
        {
            food:'Pupusas',
            img:"./Pupusas.JPEG",
            types:['Queso','Chicharron','Frijoles Con Queso','Revuelta','Loroco' ,'Espinaca','Pollo','Puerco']
        },

        {
            food:'Platio De Sopes',
            img:"./platilloDeSopes.JPEG"
        
        },

        {
            food:'Platio De Quesadillas',
            img:"./platioDeQuesadillas.JPEG",
            types:['* Carne De Su Preferencia']
        },

        {
            food:'Hamburguesa',
            img:"./Hamburgesa.JPEG"
        },
        {
            food:'Enchiladas Rojas',
            img:"./enchiladasRojas.JPEG"
        },
        {
            food:'Platio De Flauta',
            img:"./platioDeFlauta.JPEG"
        },
        {
            food:'Menudo',
            img:"./menudo.JPEG"
        },
        
        
        {
            food:'Platio De Pollo Quisado',
            img:"./platillosDePolloQuisado.JPEG"

        },
        {
            food:'Bistec Encebollado',
            img:"./visteEncebollado.JPEG"
        }
        
    ]

  return (
    <div className="container">
        <Banner/>
        <Header img={img1}/>
        <Section meals={appetizerArray} />
        <Header img={img2}/>
        <Section meals={mainFoodArray} />
        <Banner/>


    </div>
  )
}

export default Container